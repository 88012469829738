import 'react-quill/dist/quill.snow.css';

import React, {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { AnswerModel } from '../../model/answer_model';
import { Item4 } from '../../model/test_model';

const QuestionWriting = (props: any) => {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState<string>(props.data.answer && props.data.answer.answerData? props.data.answer.answerData.alphaData: "");
  const [wordCount, setWordCount] = useState<number>(0);
  var data: Item4 = props.data;
  
  const handleTextChange = (
    content: string,
    _delta: any,
    _source: string,
    _editor: any
  ) => {
    _editor.getLength();
    const trimmedContent = content.trim();
    setText(trimmedContent);
    var regex = /(<([^>]+)>)/ig;
    const words = trimmedContent === "" ? 0 : trimmedContent.replace(regex, '')==''?0:trimmedContent.replace(regex, '').split(/\s+/).length;
    setWordCount(words);
    if(words && minWords && (words<minWords || words<minLength)){
      props.onAnswer(new AnswerModel());
      return;
    }

    let answerModel = null;
    if (
      trimmedContent.replace(/ /g,'') !== "<p><br></p>" &&
      trimmedContent.replace(/ /g,'') !== "<p></p>" &&
      trimmedContent.replace(/ /g,'').length > 7
    ) {
      answerModel = new AnswerModel({
        questionCode: data.questionCode,
        answerData: {
          alphaData: trimmedContent,
        },
      });
    } else {
      answerModel = new AnswerModel();
    }
    props.onAnswer(answerModel);
  };

  const modules = {
    toolbar: [
      [
        // { header: "1" }, { header: "2" }, 
        // { font: [] }
      ],
      [{ size: [] }],
      [
        "bold", "italic", 
        // "underline", "strike", "blockquote"
      ],
      [
        { list: "bullet" },
        { list: "ordered" },
        { list: "ordered" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      // ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  
  const minWords = data.validationCondition?.min;
  const maxWords = data.validationCondition?.max;
  const maxLength = data.condition?.maxLength;
  const minLength = data.condition?.minLength;

  useEffect(() => {
    var regex = /(<([^>]+)>)/ig;
    const words = text === "" ? 0 : text.replace(regex, '')==''?0:text.replace(regex, '').split(/\s+/).length;
    setWordCount(words);
  }, [data.response]);
  
  return (
    <div className="md:flex gap-2">
      
      <div className="md:w-[50vw] p-2 md:max-h-[60vh] overflow-y-auto">
        {data["showPosition"] && data.questionNumber && <span className="inline mt-1">{data.questionNumber}.&nbsp;</span>}
        {data.renderedQuestion}
        {/* <span dangerouslySetInnerHTML={{__html:data.question}}></span> */}
      </div>
      
      <div className="md:w-[50vw] mt-5 md:mt-0 md:max-h-[60vh]">
        <ReactQuill
          className="h-[50vh]"
          defaultValue={text}
          onChange={handleTextChange}
          tabIndex={0}
          preserveWhitespace={true}
          onKeyDown={(event) => {
            if((maxWords>0 && maxWords<=wordCount && (event.keyCode>45 || event.keyCode===32))) {
              event.preventDefault();
            }
          }}
          modules={modules}
          formats={formats}
          placeholder={`${t("write_response")} (Min: ${minWords || minLength || 1} ${t("word")} , Max: ${maxWords || maxLength} ${t("word")} )`}
        />
        {
          (maxWords || maxLength)?
          <div
            className={`m-2 sm:mt-12 mt-[7.5rem] text-sm ${
              wordCount > maxWords ? "text-red-600" : "text-gray-500"
            }`}>
            {t("word_count")}: {wordCount}/{maxWords || maxLength}
          </div>:
          <div className='mt-8'></div>
        }
      </div>
    </div>
  );
};

export default QuestionWriting;
